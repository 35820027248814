import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

import blog1 from '../../images/blog-page/1.jpg';
import blog2 from '../../images/blog-page/2.jpg';
import blog3 from '../../images/blog-page/3.jpg';
import blog4 from '../../images/blog-page/4.jpg';
import blog5 from '../../images/blog-page/5.jpg';
import avatar from '../../images/blog-page/6.jpg';

export const blogPostList = [
  {
    // image: blog1,
    meta: { avatar: avatar, name: 'By Aliza anne', level: 'Direito trabalhista', date: 'Oct 12,2018' },
    title: 'Direito trabalhista',
    text: (
      <>
        <p>
          O <strong>direito trabalhista</strong> é o ramo do direito que regula as relações entre empregados e
          empregadores, além de aspectos relacionados ao trabalho em geral. Abaixo, uma descrição detalhada sobre os
          principais temas que envolvem essa área:
        </p>
        <h4>Relação de Emprego</h4>
        <p>
          O direito trabalhista define as condições de trabalho e os direitos e deveres tanto do empregado quanto do
          empregador. Os principais elementos que caracterizam a relação de emprego incluem:
        </p>
        <ul>
          <li>
            <strong>Subordinação</strong>: o empregado deve seguir as ordens do empregador.
          </li>
          <li>
            <strong>Onerosidade</strong>: o trabalho deve ser remunerado.
          </li>
          <li>
            <strong>Pessoalidade</strong>: o contrato de trabalho é pessoal, ou seja, o empregado não pode ser
            substituído por outra pessoa.
          </li>
          <li>
            <strong>Não eventualidade</strong>: o serviço deve ser prestado de forma contínua e habitual.
          </li>
        </ul>
        <h4>Jornada de Trabalho</h4>
        <p>
          As regras sobre a duração do trabalho são de extrema importância para o equilíbrio da relação entre empregado
          e empregador. No Brasil, a jornada padrão é de <strong>8 horas diárias</strong> e{' '}
          <strong>44 horas semanais</strong>, com direito a intervalos para descanso e alimentação.
        </p>
        <h4>Salário e Remuneração</h4>
        <p>
          O direito trabalhista garante que o empregado receba uma contraprestação pelo seu trabalho. Além do salário,
          podem ser incluídos outros benefícios, como:
        </p>
        <ul>
          <li>
            <strong>Adicionais</strong> (noturno, periculosidade, insalubridade);
          </li>
          <li>
            <strong>Horas extras</strong>;
          </li>
          <li>
            <strong>13º salário</strong>;
          </li>
          <li>
            <strong>Férias remuneradas</strong>.
          </li>
        </ul>
        <h4>Direitos dos Trabalhadores</h4>
        <p>O direito trabalhista assegura uma série de garantias e direitos mínimos aos trabalhadores, como:</p>
        <ul>
          <li>
            <strong>Férias</strong>: direito a 30 dias de férias anuais, com adicional de 1/3 do salário.
          </li>
          <li>
            <strong>FGTS</strong>: Fundo de Garantia por Tempo de Serviço, depositado mensalmente pelo empregador.
          </li>
          <li>
            <strong>Seguro-desemprego</strong>: benefício para trabalhadores demitidos sem justa causa.
          </li>
          <li>
            <strong>Licença-maternidade e paternidade</strong>: períodos de afastamento remunerado para os pais após o
            nascimento ou adoção de filhos.
          </li>
          <li>
            <strong>Aviso prévio</strong>: direito de ser notificado com antecedência em caso de demissão.
          </li>
        </ul>
        <h4>Condições de Trabalho</h4>
        <p>A legislação trabalhista também se preocupa com as condições em que o trabalho é realizado. Isso inclui:</p>
        <ul>
          <li>
            Garantir um <strong>ambiente de trabalho seguro</strong> e saudável.
          </li>
          <li>
            Regular as normas sobre <strong>equipamentos de proteção individual (EPIs)</strong>.
          </li>
          <li>
            Proibir condições de <strong>trabalho degradantes</strong>, como o trabalho infantil ou forçado.
          </li>
        </ul>
        <h4>Rescisão do Contrato de Trabalho</h4>
        <p>
          O direito trabalhista regula as formas de término do contrato de trabalho, que pode ocorrer por iniciativa do
          empregador ou do empregado. Em cada caso, há direitos que precisam ser respeitados, como o pagamento de verbas
          rescisórias, saldo de salário, férias proporcionais, e outros.
        </p>
        <h4>Justiça do Trabalho</h4>
        <p>
          A <strong>Justiça do Trabalho</strong> é o ramo do Judiciário responsável por resolver os conflitos
          relacionados às relações de trabalho. Ela trata de:
        </p>
        <ul>
          <li>
            <strong>Reclamações trabalhistas</strong>;
          </li>
          <li>
            <strong>Demandas por verbas não pagas</strong>;
          </li>
          <li>
            <strong>Ações de indenização</strong> por acidente de trabalho, entre outros.
          </li>
        </ul>
        <h4>Negociação Coletiva</h4>
        <p>
          O direito trabalhista também contempla a <strong>negociação coletiva</strong> entre trabalhadores e
          empregadores, geralmente representados por <strong>sindicatos</strong>. Essas negociações podem resultar em
          convenções ou acordos coletivos que regulamentam questões como:
        </p>
        <ul>
          <li>Reajustes salariais;</li>
          <li>Benefícios adicionais;</li>
          <li>Condições específicas de trabalho para determinadas categorias.</li>
        </ul>

        <h4>Conclusão</h4>
        <p>
          O direito trabalhista busca garantir um equilíbrio na relação entre empregadores e empregados, proporcionando
          proteção aos trabalhadores sem prejudicar os interesses dos empregadores. Ele está em constante evolução,
          acompanhando as mudanças nas formas de trabalho e nas necessidades sociais e econômicas do país.
        </p>
      </>
    ),
  },
  {
    // image: blog2,
    title: 'Direito processual civil',
    meta: { avatar: avatar, name: 'By Aliza anne', level: 'Direito processual civil', date: 'Oct 12,2018' },
    text: (
      <>
        <p>
          O <strong>direito processual civil</strong> é o ramo do direito que regula o processo judicial no âmbito
          civil, ou seja, as regras e procedimentos que devem ser seguidos para que as pessoas possam buscar a tutela de
          seus direitos junto ao Poder Judiciário. Abaixo, uma descrição detalhada dos principais aspectos que envolvem
          essa área:
        </p>
        <h4>Objetivo do Direito Processual Civil</h4>
        <p>
          O direito processual civil tem como principal objetivo garantir a aplicação justa e eficaz do direito material
          (como o direito civil, por exemplo), por meio de um processo adequado, que assegure aos litigantes o{' '}
          <strong>direito ao contraditório</strong> e à <strong>ampla defesa</strong>. Ele disciplina como as partes
          devem se comportar durante o processo, quais são seus direitos e deveres e qual o papel do juiz e demais
          operadores do direito.
        </p>
        <h4>Princípios Fundamentais</h4>
        <p>O direito processual civil é baseado em uma série de princípios fundamentais, como:</p>
        <ul>
          <li>
            <strong>Devido processo legal</strong>: o processo deve seguir um conjunto de regras estabelecidas para que
            haja justiça.
          </li>
          <li>
            <strong>Contraditório</strong>: as partes têm o direito de participar do processo, apresentando suas versões
            e influenciando o resultado.
          </li>
          <li>
            <strong>Ampla defesa</strong>: direito de utilizar todos os meios e recursos disponíveis para defender-se no
            processo.
          </li>
          <li>
            <strong>Igualdade das partes</strong>: ambas as partes devem ter o mesmo tratamento no processo.
          </li>
          <li>
            <strong>Celeridade processual</strong>: o processo deve ser conduzido de forma rápida e eficiente, evitando
            atrasos injustificados.
          </li>
        </ul>
        <h4>Estrutura do Processo</h4>
        <p>
          O processo civil é dividido em várias fases, que são fundamentais para o andamento correto do procedimento:
        </p>
        <ul>
          <li>
            <strong>Fase Postulatória</strong>: é o momento em que as partes apresentam suas alegações iniciais, com o
            autor propondo a ação através da <strong>petição inicial</strong> e o réu apresentando sua{' '}
            <strong>contestação</strong>.
          </li>
          <li>
            <strong>Fase de Instrução</strong>: onde são produzidas as provas que irão subsidiar a decisão do juiz, como
            depoimentos de testemunhas, documentos, perícias e outros elementos de prova.
          </li>
          <li>
            <strong>Fase Decisória</strong>: o juiz analisa o material apresentado e proferirá a{' '}
            <strong>sentença</strong>, resolvendo a questão trazida ao tribunal.
          </li>
          <li>
            <strong>Fase Recursal</strong>: caso uma das partes não concorde com a decisão, poderá recorrer, pedindo uma
            nova análise da questão.
          </li>
          <li>
            <strong>Cumprimento de Sentença</strong>: uma vez proferida a decisão, esta deve ser executada. Se o réu não
            cumprir voluntariamente, podem ser iniciados atos de <strong>execução forçada</strong>.
          </li>
        </ul>
        <h4>Tipos de Ação</h4>
        <p>
          No direito processual civil, existem diferentes tipos de ações que podem ser propostas, cada uma com
          características específicas:
        </p>
        <ul>
          <li>
            <strong>Ação de Conhecimento</strong>: é a ação mais comum, onde se busca o reconhecimento de um direito.
            Nela, o juiz analisa os fatos e aplica o direito ao caso concreto.
          </li>
          <li>
            <strong>Ação Cautelar</strong>: é utilizada para garantir que o direito não seja prejudicado enquanto o
            processo principal é julgado. Um exemplo é a <strong>tutela antecipada</strong> ou <strong>liminar</strong>,
            que concede uma decisão provisória.
          </li>
          <li>
            <strong>Ação de Execução</strong>: quando há um título executivo (como um contrato ou uma sentença), esta
            ação busca a satisfação do direito, ou seja, forçar o devedor a cumprir uma obrigação.
          </li>
        </ul>
        <h4>Partes no Processo</h4>
        <p>O processo civil envolve várias partes, cada uma com funções específicas:</p>
        <ul>
          <li>
            <strong>Autor</strong>: a pessoa que propõe a ação, buscando a proteção de um direito.
          </li>
          <li>
            <strong>Réu</strong>: a parte contra quem a ação é movida, que pode apresentar sua defesa.
          </li>
          <li>
            <strong>Juiz</strong>: o responsável por garantir o andamento correto do processo e proferir a decisão
            final.
          </li>
          <li>
            <strong>Advogados</strong>: representam as partes, elaboram petições, recursos e participam das audiências.
          </li>
        </ul>
        <h4>Meios de Prova</h4>
        <p>
          No direito processual civil, as partes têm o direito de apresentar provas para demonstrar a veracidade de suas
          alegações. Alguns exemplos de meios de prova incluem:
        </p>
        <ul>
          <li>
            <strong>Documentos</strong>: como contratos, recibos, cartas e e-mails.
          </li>
          <li>
            <strong>Testemunhas</strong>: pessoas que têm conhecimento dos fatos envolvidos no processo.
          </li>
          <li>
            <strong>Perícias</strong>: exames técnicos realizados por especialistas, como contadores ou médicos, para
            esclarecer questões técnicas.
          </li>
          <li>
            <strong>Inspeção judicial</strong>: quando o juiz vai pessoalmente ao local dos fatos para verificar o que
            foi alegado.
          </li>
        </ul>
        <h4>Recursos</h4>
        <p>
          O direito processual civil também prevê a possibilidade de as partes recorrerem das decisões que considerem
          injustas ou incorretas. Entre os principais recursos estão:
        </p>
        <ul>
          <li>
            <strong>Apelação</strong>: interposto contra a sentença, buscando a reforma ou anulação da decisão.
          </li>
          <li>
            <strong>Agravo</strong>: utilizado para atacar decisões interlocutórias, ou seja, aquelas que não põem fim
            ao processo, mas resolvem questões no meio dele.
          </li>
          <li>
            <strong>Embargos de declaração</strong>: usados para pedir esclarecimentos quando há obscuridade,
            contradição ou omissão na decisão judicial.
          </li>
        </ul>
        <h4>Processo Eletrônico</h4>
        <p>
          Com o avanço da tecnologia, o direito processual civil vem se adaptando ao uso de plataformas digitais para a
          tramitação dos processos. O <strong>processo eletrônico</strong> permite maior celeridade e transparência,
          possibilitando o envio de petições, a prática de atos processuais e o acompanhamento de julgamentos via
          sistemas online.
        </p>
        <h4>Execução e Cumprimento de Sentença</h4>
        <p>
          Após o julgamento final, é necessário garantir que a sentença seja cumprida. Se a parte condenada não cumprir
          espontaneamente a decisão, o processo pode entrar em fase de <strong>execução</strong>, onde medidas como
          penhora de bens, bloqueio de contas bancárias e outras ações coercitivas podem ser realizadas para assegurar o
          cumprimento da decisão judicial.
        </p>
        <h4>Conclusão</h4>
        <p>
          O direito processual civil é essencial para garantir que os litígios sejam resolvidos de forma justa, seguindo
          um procedimento legal adequado. Ele regula toda a dinâmica do processo judicial, desde a petição inicial até a
          execução da sentença, assegurando que as partes possam buscar a tutela de seus direitos de maneira organizada
          e eficiente.
        </p>
      </>
    ),
  },
];
const BlogPost = () => {
  return (
    <Fragment>
      {blogPostList.map((blog, i) => (
        <div key={i} className="blogPostWrapper">
          <div className="blogPostImg">
            <img src={blog.image} alt="" />
          </div>
          <div className="blogPostContent">
            <ul className="blogPostMeta">
              <Fragment key={i}>
                <li>
                  <img src={blog.meta.avatar} alt="" />
                </li>
                <li>{blog.meta.name}</li>
                <li>{blog.meta.level}</li>
                <li>{blog.meta.date}</li>
              </Fragment>
            </ul>
            <h3>
              <Link to="blog-details">{blog.title}</Link>
            </h3>
            <p>{blog.text}</p>
            {/* <Link className="readmore" to="blog-details">
              Read more..
            </Link> */}
          </div>
        </div>
      ))}
    </Fragment>
  );
};
export default BlogPost;
