import React, { Fragment } from 'react';
import HeaderBotton from '../../../components/HeaderBottom';
import HeaderTop from '../../../components/HeaderTop';
import Breadcumb from '../../../components/Breadcumb';
import Service from '../../../components/Service';
import ServiceArea from '../../../components/ServiceArea';
import Testmonial from '../../../components/Testmonial';
import PricingTable from '../../../components/PricingTable';
import NewsLetter from '../../../components/Newsletter';
import FooterArea from '../../../components/FooterArea';
// images
import breadcumb from '../../../images/breadcumb/1.jpg';

import './style.scss';

const breadcumbMenu = [{ name: 'Início', route: '/' }, { name: 'Área de atuação' }];

const PracticePage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>

      <Breadcumb
        className="breadcumbArea"
        title="Área de atuação"
        breadcumbMenu={breadcumbMenu}
        background={breadcumb}
      />

      <Service className="serviceArea mt-0" />

      <ServiceArea extraClassName="ourServiceAreaStyleThree bgFFF" />

      {/* <PricingTable className="pricingTableArea" title="Pricing Table" subTitle="Our Pricing Plan" /> */}

      {/* <Testmonial className="testmonialArea pt100" /> */}

      <NewsLetter className="newsLetterArea" />

      <FooterArea />
    </Fragment>
  );
};
export default PracticePage;
