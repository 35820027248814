import React, { Fragment } from 'react';
import HeaderBotton from '../../../components/HeaderBottom';
import HeaderTop from '../../../components/HeaderTop';
import Breadcumb from '../../../components/Breadcumb';
import NewsLetter from '../../../components/Newsletter';
import FooterArea from '../../../components/FooterArea';
import Portfolio from '../../../components/Portfolio';
// images
import breadcumb from '../../../images/breadcumb/1.jpg';
// images
import portfolio1 from '../../../images/studies/1.jpg';
import portfolio2 from '../../../images/studies/2.jpg';
import portfolio3 from '../../../images/studies/3.jpg';
import portfolio4 from '../../../images/studies/4.jpg';
import portfolio5 from '../../../images/studies/5.jpg';

import './style.scss';

const breadcumbMenu = [{ name: 'Home', route: '/' }, { name: 'Resent Case Studies' }];

// const portfolioItem = [
//     { images: portfolio1, title: 'General Service', subtitle: 'Corporate' },
//     { images: portfolio2, title: 'Personal Issue', subtitle: 'General' },
//     { images: portfolio3, title: 'Business Accounting', subtitle: 'Business' },
//     { images: portfolio4, title: 'Accounting issue', subtitle: 'Criminal' },
//     { images: portfolio5, title: 'Business Accounting', subtitle: 'Family Issue' },
//     { images: portfolio1, title: 'General Service', subtitle: 'Corporate' },
//     { images: portfolio2, title: 'Personal Issue', subtitle: 'General' },
//     { images: portfolio3, title: 'Business Accounting', subtitle: 'Business' },
//     { images: portfolio4, title: 'Accounting issue', subtitle: 'Criminal' },
// ]

const portfolioItem = [
  { images: portfolio1, title: 'General Service', subtitle: 'Corporate' },
  { images: portfolio2, title: 'Personal Issue', subtitle: 'General' },
  { images: portfolio3, title: 'Business Accounting', subtitle: 'Business' },
  { images: portfolio4, title: 'Accounting issue', subtitle: 'Criminal' },
  { images: portfolio5, title: 'Business Accounting', subtitle: 'Family Issue' },
];

const PortfolioPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb className="breadcumbArea" title="Case Stadies" breadcumbMenu={breadcumbMenu} background={breadcumb} />

      <Portfolio
        title="Our Resent Case Studies"
        subTitle="Here Our Best Work"
        fullWidth={true}
        portfolioItem={portfolioItem}
        className="portfolioArea portfolioAreaStyleTwo"
      />

      <NewsLetter className="newsLetterArea" />
      <FooterArea />
    </Fragment>
  );
};
export default PortfolioPage;
