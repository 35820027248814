import React from 'react';
import SectionTitle from '../Title';
import './style.scss';

const services = [
  {
    icon: 'flaticon-employee',
    title: 'Direito trabalhista',
    content:
      'O direito trabalhista é o ramo do direito que regula as relações entre empregados e empregadores, buscando equilibrar os interesses de ambas as partes. Ele define direitos e deveres no ambiente de trabalho, como jornada, salário, férias, e condições de trabalho, além de prever mecanismos de proteção ao trabalhador, como o Fundo de Garantia do Tempo de Serviço (FGTS), seguro-desemprego, e direitos em casos de rescisão de contrato. Também inclui normas sobre segurança no trabalho e questões coletivas, como negociação sindical e acordos trabalhistas.',
  },
  {
    icon: 'flaticon-parents',
    title: 'Direito processual civil',
    content:
      'O direito processual civil é o ramo do direito que regula o conjunto de regras e procedimentos a serem seguidos nas disputas judiciais de natureza civil. Ele define como se dá o andamento de um processo, desde a apresentação da ação até a execução da sentença, garantindo que os litigantes tenham o direito ao contraditório e à ampla defesa. O objetivo principal é assegurar a aplicação justa do direito material (como o direito civil) por meio de um processo adequado, disciplinando as funções do juiz, das partes e dos advogados, bem como a produção de provas e os recursos disponíveis.',
  },
  // {
  //   icon: 'flaticon-wounded',
  //   title: 'Danos pessoais',
  //   content:
  //     'Danos pessoais no direito referem-se a prejuízos físicos, emocionais ou psicológicos que uma pessoa sofre em decorrência de um ato ilícito ou de um acidente.',
  // },
  // {
  //   icon: 'flaticon-thief',
  //   title: 'Direito criminal',
  //   content:
  //     'O direito criminal é um ramo do direito que regula as infrações penais e estabelece as normas para a persecução e punição dos crimes.',
  // },
  // {
  //   icon: 'flaticon-university-graduate-hat',
  //   title: 'Education Law',
  //   content: 'It is a long established fact that a reader will be distracted by the readable content of ',
  // },
  // {
  //   icon: 'flaticon-house',
  //   title: 'Real Estate Law',
  //   content: 'It is a long established fact that a reader will be distracted by the readable content of ',
  // },
];

const ServiceArea = ({ extraClassName = '', title = 'Como podemos lhe ajudar', subTitle = 'Áreas de prática' }) => {
  return (
    <div className={`ourServiceArea ${extraClassName}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title={title} subTitle={subTitle} />
          </div>
          {services.map((service, index) => (
            <div key={index} className="col-lg-6 col-md-6">
              <div className="serviceWrap">
                <div className="serviceIcon">
                  <i className={`fi ${service.icon}`}></i>
                </div>
                <div className="serviceContent">
                  <h3>{service.title}</h3>
                  <p>{service.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ServiceArea;
