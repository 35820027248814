import React, { Fragment } from 'react';
import HeaderBotton from '../../../components/HeaderBottom';
import HeaderTop from '../../../components/HeaderTop';
import HeroSlider from '../../../components/HeroSlider';
import Service from '../../../components/Service';
import About from '../../../components/About';
import ServiceArea from '../../../components/ServiceArea';
import Portfolio from '../../../components/Portfolio';
import Testmonial from '../../../components/Testmonial';
import ContactArea from '../../../components/ContactArea';
import TeamMember from '../../../components/TeamMember';
import CounterArea from '../../../components/CounterArea';
import BlogArea from '../../../components/BlogArea';
import NewsLetter from '../../../components/Newsletter';
import FooterArea from '../../../components/FooterArea';

const HomePageOne = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea" />
      </header>

      {/* <HeroSlider  className="heroSliderArea" /> */}

      <Service className="serviceArea" />

      <About title="Sobre" signature={null} showKnowMore />

      <ServiceArea />

      {/* <Portfolio
        className="portfolioArea"
        title="Our Resent Case Studies"
        subTitle="Here Our Best Work"
      /> */}

      {/* <Testmonial className="testmonialArea" /> */}

      {/* <ContactArea className="contactArea" /> */}

      <TeamMember slider={true} />

      {/* <CounterArea className="counterArea" /> */}

      {/* <BlogArea /> */}

      <NewsLetter className="newsLetterArea" />

      <FooterArea />
    </Fragment>
  );
};
export default HomePageOne;
