import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo/logo.png';
import './style.scss';
const footerLinks = [
  {
    title: 'Quick Link',
    menus: [
      { name: 'Início', route: '/' },
      { name: 'Área de atuação', route: 'practice' },
      { name: 'Nosso time', route: 'team' },
      { name: 'Blog', route: 'blog' },
    ],
  },
  {
    title: 'Área de atuação',
    menus: [
      { name: 'Direito trabalhista', route: 'home' },
      { name: 'Direito civil', route: 'home' },
    ],
  },
  {
    title: 'Contato',
    menus: [
      { name: 'Rua Pedro Neis, nº 147, Santa Terezinha, Bom Princípio - RS' },
      { name: 'Fone/Whatsapp: (51) 99699-2993' },
      { name: 'leduresalva@gmail.com', isEmail: true },
    ],
  },
];

const FooterArea = () => {
  return (
    <footer className="footerArea">
      <div className="footerTopArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footerLogo">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
                <p>
                  Escritório de advocacia Ledur & Salvá, onde a excelência, ética e foco no cliente são os pilares de
                  nossa atuação.
                </p>
              </div>
            </div>
            {footerLinks.map((menu, i) => (
              <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                <div className="footerWrap">
                  <h3>{menu.title}</h3>
                  <ul>
                    {menu.menus.map((item, i) => {
                      if (item.isEmail) {
                        return (
                          <li key={i}>
                            <a href={`mailto:${item.name}`}>E-mail: {item.name}</a>
                          </li>
                        );
                      }
                      return (
                        <li key={i}>{item.route ? <Link to={`/${item.route}`}>{item.name}</Link> : `${item.name}`} </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footerBottomArea">
        <div className="container">
          <div className="footerBottomContent">
            <div className="row">
              <div className="col-md-8 col-sm-10 col-12">
                <span>© {new Date().getFullYear()} Ledur & Salvá. Todos os direitos reservados.</span>
              </div>
              <div className="col-md-4 col-sm-2 col-12">
                <ul className="socialListFooter">
                  <li>
                    <a href="https://www.facebook.com/profile.php?id=61566510969591">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterArea;
