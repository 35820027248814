import React from 'react';
import './style.scss';

const services = [
  { icon: 'fi flaticon-lawyer', subtitle: 'Excelência, ética e', title: 'Competência' },
  { icon: 'fi flaticon-scale', subtitle: 'Soluções jurídicas', title: 'Personalizadas' },
  { icon: 'fi flaticon-network', subtitle: 'Foco no', title: 'Cliente' },
];
const Service = (props) => {
  return (
    <div className={props.className}>
      <div className="container">
        <div className="row">
          {services.map((service, i) => (
            <div key={i} className="col-lg-4 col-md-6">
              <div className="serviceItem">
                <div className="serviceIcon">
                  <i className={service.icon}></i>
                </div>
                <div className="serviceText">
                  <span>{service.subtitle}</span>
                  <h3>{service.title}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Service;
