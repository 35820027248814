import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import 'react-modal-video/scss/modal-video.scss';
import './style.scss';
import aboutImage from '../../images/about/2.jpg';
// import signature from '../../../images/about/1.png';
const signature = null;

const pragraphs = [
  {
    text: 'Bem-vindo ao escritório de advocacia Ledur & Salvá, onde a excelência e a ética são os pilares de nossa atuação. Fundado pelos experientes advogados Juliano Salvá e Vinícius Ledur, nosso escritório se destaca pela dedicação e pelo compromisso em oferecer soluções jurídicas personalizadas e eficazes. ',
  },
  {
    text: 'Com uma abordagem centrada no cliente, trabalhamos em diversas áreas do direito, sempre buscando a melhor estratégia para cada caso. Nossa equipe acredita que a confiança e a transparência são fundamentais para construir relacionamentos duradouros, e estamos prontos para enfrentar os desafios legais com profissionalismo e competência. Aqui, sua causa é nossa prioridade.',
  },
  {
    text: (
      <i>
        Werner Vinícus Ledur (OAB/RS 100.956), <br />
        Juliano de Andrade Salvá (OAB/RS 82.940)
      </i>
    ),
  },
];

const About = ({
  title = 'Sobre',
  subTitle,
  videoId,
  extraClassName = '',
  orderLast = false,
  showKnowMore = false,
  showMission = false,
}) => {
  const [video, setVideo] = useState(false);
  return (
    <div className={`aboutArea ${extraClassName}`}>
      <div className="container">
        <div className="row">
          <div className={orderLast ? 'order-last col col-lg-6' : 'col col-lg-6'}>
            <div className="aboutImageWrap">
              <img src={aboutImage} alt="" />
              {videoId && <i onClick={() => setVideo(true)} className="fa fa-play"></i>}
              <ModalVideo channel="youtube" isOpen={video} videoId={videoId} onClose={() => () => setVideo(false)} />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="aboutContent">
              {subTitle && <span>{subTitle}</span>}
              <h2>{title}</h2>
              {pragraphs.map((pragraph, i) => (
                <p key={i}>{pragraph.text}</p>
              ))}
              {showKnowMore && (
                <div className="btnStyle">
                  <Link to="/about">Saiba mais...</Link>
                </div>
              )}
              {signature && (
                <div className="signature">
                  <img src={signature} alt="" />
                </div>
              )}

              {showMission && (
                <>
                  <h2>Missão</h2>

                  <p>
                    Nosso escritório tem como missão ser reconhecido pela{' '}
                    <strong>excelência na prestação de serviços jurídicos</strong>, garantindo qualidade, rapidez e
                    eficácia em todas as nossas atuações. Buscamos sempre proporcionar soluções jurídicas precisas e
                    eficientes, com foco total na <strong>satisfação do cliente</strong>. Nosso compromisso é atuar de
                    forma proativa e estratégica, atendendo às necessidades de cada caso com agilidade e transparência,
                    promovendo segurança jurídica e confiança em cada ação.
                  </p>

                  <h2>Visão</h2>

                  <p>
                    Nossa visão é ser mais do que um prestador de serviços jurídicos – queremos ser um{' '}
                    <strong>verdadeiro parceiro de negócios</strong>, atuando lado a lado com nossos clientes. Almejamos
                    construir relações de confiança duradouras, oferecendo suporte jurídico contínuo e personalizado.
                    Acreditamos que o sucesso dos nossos clientes é também o nosso sucesso, e estamos comprometidos em
                    caminhar juntos rumo aos melhores resultados possíveis.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
