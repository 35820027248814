import React, { Fragment } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SectionTitle from '../Title';
import './style.scss';

// images
import teamMember1 from '../../images/ledur.jpg';
import teamMember2 from '../../images/juliano.jpeg';
import teamMember3 from '../../images/expert/3.jpg';
import teamMember4 from '../../images/expert/4.jpg';
import teamMember5 from '../../images/expert/5.jpg';
import teamMember6 from '../../images/expert/6.jpg';
import { Link } from 'react-router-dom';

const teams = [
  {
    name: 'Werner Vinícus Ledur (OAB/RS 100.956)',
    level: 'Especialista em Direito e Processo do Trabalho',
    image: teamMember1,
    socialMedia: ['facebook', 'twitter', 'linkedin'],
    bio: (
      <>
        <p>
          <b>Vinícius Ledur</b> é um advogado gaúcho de destaque na área trabalhista, com formação em Direito pela
          Pontifícia Universidade Católica do Rio Grande do Sul (PUC-RS). Reconhecido por sua sólida atuação, Vinícius
          construiu uma reputação de excelência, principalmente em causas relacionadas ao setor bancário, onde demonstra
          um conhecimento profundo das questões trabalhistas específicas dessa área.
        </p>

        <p>
          Uma de suas marcas profissionais é o foco absoluto no cliente, sempre priorizando as necessidades e
          expectativas daqueles que o procuram. Além disso, é admirado por sua praticidade e agilidade na resolução de
          casos, buscando sempre soluções rápidas e eficazes. Essa abordagem proativa e centrada no resultado lhe rendeu
          o respeito de seus clientes e colegas de profissão.
        </p>

        <p>
          Sua capacidade de traduzir questões jurídicas complexas em estratégias claras e executáveis faz com que
          Vinícius Ledur seja visto como um advogado confiável e eficiente, especialmente no campo trabalhista, onde os
          desafios são frequentes e exigem precisão e rapidez na tomada de decisões.
        </p>
      </>
    ),
  },
  {
    name: 'Juliano de Andrade Salvá (OAB/RS 82.940)',
    level: 'Especialista em Direito e Processo do Trabalho',
    image: teamMember2,
    socialMedia: ['facebook', 'twitter', 'linkedin'],
    bio: (
      <>
        <p>
          <b>Juliano de Andrade Salvá</b> é um renomado advogado especialista em Direito Trabalhista, Processo do
          Trabalho e Direito Processual Civil, com vasta experiência e reconhecimento por sua atuação destacada na área.
          Nascido em Maceió, Alagoas, é filho de pais gaúchos e, desde cedo, desenvolveu uma forte conexão com o Rio
          Grande do Sul, estado que escolheu para construir sua carreira jurídica.
        </p>

        <p>
          Formado em Direito pela Pontifícia Universidade Católica do Rio Grande do Sul (PUC-RS), Juliano sempre se
          destacou pelo profundo conhecimento técnico e pela dedicação em suas causas, especialmente no campo do direito
          trabalhista. Ao longo dos anos, construiu uma reputação sólida em Porto Alegre, onde sempre atuou, sendo
          reconhecido pela eficácia de suas ações e pelo comprometimento com os interesses de seus clientes.
        </p>

        <p>
          Juliano também investiu continuamente em sua formação acadêmica, contando com duas pós-graduações nas áreas em
          que atua, o que reforça ainda mais sua capacidade de lidar com os complexos desafios do direito contemporâneo.
          Com uma carreira marcada pelo sucesso em demandas trabalhistas, ele é considerado uma referência no campo,
          admirado tanto pelos colegas de profissão quanto pelos clientes, que confiam em sua habilidade de oferecer
          soluções jurídicas precisas e eficazes.
        </p>

        <p>
          Além de sua expertise técnica, Juliano de Andrade Salvá é conhecido por sua ética profissional e por manter
          relações próximas e transparentes com seus clientes, o que contribui para seu sucesso contínuo na advocacia
          trabalhista.
        </p>
      </>
    ),
  },
];

const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const TeamMember = ({
  extraClassName = '',
  title = 'Advogados qualificados ',
  subTitle = 'Conheça nossos especialistas',
  slider,
  noGutters,
  detailed,
}) => {
  if (detailed) {
    return (
      <div className={`teamArea ${extraClassName}`}>
        <div className="container">
          <div className={!noGutters ? 'row' : 'row no-gutters'}>
            <div className="col-12">
              <SectionTitle title={title} subTitle={subTitle} />
            </div>
            <Fragment>
              {teams.map((team, i) => (
                <div className="row" key={i} style={{ paddingBottom: '100px' }}>
                  {(i + 1) % 2 === 0 ? (
                    <>
                      <div className="col-lg-6 col-md-6 col-6">{team.bio}</div>
                      <div className="col-lg-6 col-md-6 col-6">
                        <div className="teamWrapper">
                          <TeamCard team={team} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-lg-6 col-md-6 col-6">
                        <div className="teamWrapper">
                          <TeamCard team={team} />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-6">{team.bio}</div>
                    </>
                  )}
                </div>
              ))}
            </Fragment>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`teamArea ${extraClassName}`}>
      <div className="container">
        <div className={!noGutters ? 'row' : 'row no-gutters'}>
          <div className="col-12">
            <SectionTitle title={title} subTitle={subTitle} />
          </div>
          {slider ? (
            <div className="col-12">
              <Slider className="teamSlideArea" {...settings}>
                {teams.map((team, i) => (
                  <div key={i} className="teamWrapper">
                    <TeamCard team={team} />
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <Fragment>
              {teams.map((team, i) => (
                <div key={i} className="col-lg-6 col-md-6 col-12">
                  <div className="teamWrapper">
                    <TeamCard team={team} />
                  </div>
                </div>
              ))}
            </Fragment>
          )}
        </div>
        <br />
        <div className="row">
          <div className="col-12 text-center">
            <div className="btnStyle">
              <Link to="/attorneys">Saiba mais sobre nossos advogados...</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TeamMember;

function TeamCard({ team }) {
  return (
    <>
      <TeamCardImage team={team} />
      <TeamCardContent team={team} />
    </>
  );
}

function TeamCardImage({ team }) {
  return (
    <div className="teamImage">
      <img src={team.image} alt="" />
    </div>
  );
}

function TeamCardContent({ team }) {
  return (
    <div className="teamContent">
      <h3>{team.name}</h3>
      <span>{team.level}</span>
      <ul>
        {team.socialMedia.map((social) => (
          <li key={social}>
            <a href="#">
              <i className={`fa fa-${social}`} aria-hidden="true"></i>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
